$icon-material-outlined-font-family: 'Material Symbols Outlined';

/* fallback */
@font-face {
    font-family: $icon-material-outlined-font-family;
    font-style: normal;
    font-weight: 100 700;
    src: url('./fonts/Material/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2') format('woff2');
}

.material-symbols-outlined {
    font-family: $icon-material-outlined-font-family;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}