.pilotContainer {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}
#pilotDisplayMsg{
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
    border: 1px solid lightslategray;
    width: 350px;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #F7F7F7;

}
.pilot-auth-org-logo{
    display: block;
    padding-top: 25px;
    margin: auto;
    border: 0px solid green;
    width: 100px;
    height: 50px;
}
#pilotImgContainer{
    padding-bottom: 10px;
    width: 100%;

}
.pilotButton{
    background-color: red;
    color: white;
    border: 0px solid red;
    margin: auto;
    margin-bottom: 25px;
    width: 100%;
    height: 50px;
    cursor: pointer;
}