.OE {
    height: 100%    ;
    width: auto;
    float: right;
}

#CapabilityBand {
    background-color: #EE0000;
   // clear: both;
    height: 20px;
    position: absolute;
    left: 379px;
    top: 50px;
    right: 50%;
    width: 539px;
}

hr {
    display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

#OE-logo {
    figure{
        margin: 0;
    }
    img{
        width: 100%;
    }
    @media (max-width:629px) {
        display: none;  
        .navbar{
          display:none;
      }  
    }
}
#SPD-logo {
    figure{
        margin: 0;
    }
    img{
        width: 100%;
        max-height: 100vh;
    }
    @media (max-width:629px) {
        display: none;  
        .navbar{
          display:none;
      }  
    }
}
/*
#OE-logo {
    background-image: url("./images/OE.jpg");
    background-repeat: no-repeat;
    background-attachment: local;
   // background-position: 345px 100px;
    background-size: auto;
    height: auto;
    width: 100%;
    top: 75px;
    position: absolute;
    left: 380px;
    z-index: -2;
    max-height:100%;
    max-width:100%;
}

#OE-title {
    background-image: url("../images/OrderExpress_Logo.gif");
    background-repeat: no-repeat;
    background-attachment: local;
    height: 100px;
    width: 500px;
    top: -10px;
    position: absolute;
    left: 735px;
}

#SPD {
    background-image: url("../images/SpecialityOnline_Logo.gif");
    background-repeat: no-repeat;
    background-attachment: local;
    height: 100px;
    width: 500px;
    top: -10px;
    position: absolute;
    left: 765px;
}

#FooterArea {
    width: 537px;
    top: 300px;
    left: 25%;
    position: absolute;
}

#FooterContent {
    margin: 0 0 0 231px;
    padding: 5px 0;
    width: 537px;
    right: 387px;
    color: #007DC1;
    font-family: Helvetica, sans-serif;
    font-size: small;
    @media (max-width:629px) {
        display: none;  
        .navbar{
          display:none;
      }  
    }
}

#FooterContent a {
    color: #007DC1;
    @media (max-width:629px) {
        display: none;  
        .navbar{
          display:none;
      }  
    }
}
#FooterContent1 a {
    // margin: 0 0 0 231px;
    // padding: 5px 0;
    // width: 537px;
    // right: 387px;
    color: #007DC1;
    font-family: Helvetica, sans-serif;
    font-size: small;
    @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
        text-align: center;
        .navbar{
            display:none;
        }
    }
}

#FooterMobile {
    @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
        text-align: center;
    }
}


.wrapper { 
    border : 2px solid #000; 
    overflow:hidden;
  }
  
  .wrapper div {
     min-height: 200px;
     padding: 10px;
  }
  #one {
    float:left; 
    margin-right:20px;
    width:140px;
    border-right:2px solid #000;
  }
  #two { 
    background-color: white;
    overflow:hidden;
    margin:10px;
    border:2px dashed #ccc;
    min-height:170px;
  }
  
  @media screen and (max-width: 400px) {
     #one { 
      float: none;
      margin-right:0;
      width:auto;
      border:0;
      border-bottom:2px solid #000;    
    }
  }

 .container-wid {
    overflow: hidden;
}
.column-wid {
    float: left;
    margin: 20px;
    background-color: grey;
    padding-bottom: 100%;
    margin-bottom: -100%;
}
*/
#logo-content {
    display: none;
}

#forgot-account-username, #reactivate-account-username  {
    width: inherit;
    height: inherit;
    margin: 5px 0 8px;
}
