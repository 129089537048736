// Page Properties
// $fonts: Verdana, Arial, Helvetica, sans-serif;
@import "breakpoint.scss";
@import "roboto.scss";
@import "material.scss";
$default-border-radius: 3px;
$container-width: 320px; //300px
$container-min-width: 150px;
$container-height: 300px;
$container-min-height: 70px;
$container-margin-left: 10px;
$num-tabs: 5;

$font-family-body:'Roboto', sans-serif;
// Font Size
$font-size-heading: 25.6px;
$font-size: 16px;
$font-size-medium: 14px;
$font-size-small: 11px;
$font-size-header: 11px;
$font-size-footer: 11px;
$font-size-default: 11px;

// Font Weight
$font-weight-body: normal;
$font-weight-header: bold;

// Theme Color
$primary-bg-color: #fff;
$secondary-bg-color: #f9f9f9;

// Text Color
$header-text-color: #5e5e5e;
$dark-text-color: #6b7070;
$medium-text-color: #777;
$light-text-color: #a7a7a7;
$link-text-color: #20728b;
$placeholder-text-color: #aaa;
$error-text-color: #e34843;
$text-primary-color:#27251f;
// Button Color
$icon-button-bg-color: #fff;
$button-bg-color: #e41f35;
$button-text-color: #fff;
$dark-button-bg-color: #e41f35;
$primary-button-bg-color: #e41f35;
$success-button-bg-color: #e41f35;
$negative-button-bg-color: #e41f35;
$disabled-button-text-color: #fff;
$disabled-primary-button-text-color: #fff;
$disabled-primary-button-bg-color: #e41f35; // changing for disabled
$disabled-primary-border-color: #000000;
$error-pink-color:#b13590;
//$color-gray-text:#7c7c7c;
$color-gray-text:#6B7070;



//Default Custom Button Colors
$default-custom-button-background-color:#e41f35;
$default-custom-button-border-color: #000000;

// Button Size
$input-height: 5px;
$button-height: 5px;
$button-line-height: $input-height - 4px;
$button-padding: 0 10px;
$button-font-size: 13.5px;

// Input field
$input-bg-color: #fff;
$input-border-color: #bbb;
$input-border-color-hover: #888;
$input-border-color-focus: #888;

// Modal
$modal-bg-color: #f9f9f9;
$font-family-material-icon:'Material Symbols Outlined';

// Footer

// Other

@mixin light-button-template($bg-color) {
  @include button-template($bg-color, 1%, $dark-text-color, 22%, 25%);
  @include button-shadow(0.05);
  &:hover,
  &:active,
  &:focus {
  /* -- Buttons' Colors -- */
    box-shadow: none !important;
    border-color: rgb(0,0,0) !important;
  }
}

@mixin dark-button-template($bg-color) {
  @include button-template($bg-color, 3%, #fff, 15%, 17%);
  @include button-shadow(0.15);
  &:hover,
  &:active,
  &:focus {
  /* -- Buttons' Colors -- */
    box-shadow: none !important;
    border-color: rgb(0,0,0) !important;;
  }
}

@mixin button-shadow($drop-shadow-opacity) {
  box-shadow: rgba(#000, $drop-shadow-opacity) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

@mixin button-template($bg-color, $gradient-factor, $text-color, $border-factor, $border-bottom-factor) {
  color: $text-color;
  //background: -o-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
  //background: -ms-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* IE10+ */
  //background: linear-gradient($bg-color, darken($bg-color, $gradient-factor));
  background: $bg-color;
  background-color: $bg-color;
  border-color: $bg-color;
  border-bottom-color: $bg-color;

  &:hover,
  &:active,
  &:focus {
  /* -- Buttons' Colors -- */
    background: $bg-color;
    background-color: $bg-color;
    border-color: $bg-color;
    border-bottom-color: $bg-color;
    text-decoration: none;
    cursor: pointer;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body{
  font-family: $font-family-body !important;
  margin:0px;
}
.h-0{
  height: 0px !important;
}
.mt-4_2{
  margin-top:40px !important;
}

.mb-4_1{
  margin-bottom: 30px !important;
}

.mt-4_3{
  margin-top:45px !important;
}
.mb-4_2{
  margin-bottom:40px !important;
}
.mt-n1{
  margin-top: -0.25rem !important;
}
#okta-sign-in.auth-container.main-container{
  font-family: $font-family-body !important;
}
.login-wrapper-inner-page #okta-sign-in.auth-container .auth-org-logo{
  max-width:247px;
  width: 100%;
  left:-28px;
  @include media-xxl-max {
    max-width: 220px;
  }
  @include media-lg-max {
    max-width: 210px;
  }
  @include media-sm-max {
    max-width: 158px;
    left: -20px;
 } 
}
// scss-lint:disable IdSelector
#okta-sign-in.auth-container {
 //scss-lint:enable IdSelector

  
  &.main-container {
  /* -- Fonts and Text Colors -- */
   
    font-size: $font-size !important;
    color: $medium-text-color;
    border: 1px solid #bbb;
    box-shadow: none
  }

  h2 {
    display: none;
  }

  h3 {
  /* -- Fonts and Text Colors -- */
    font-weight: $font-weight-header;
    font-size: $font-size-header;
    color: $header-text-color;
    padding-left: 10px;
  }

  .okta-sign-in-header {
  /* -- Fonts and Text Colors -- */
  border: none;
  // height: 100px;
  padding: 0px;
    font-size: $font-size-header;
    color: $header-text-color;
    @media (max-width:576px) {
      text-align: center;
      padding: 2rem 0;
      border-bottom: 0px solid rgba(0, 0, 0, 0.1);
      margin-bottom:0.2rem;
  }
   
  }

  

  .okta-form-subtitle,
  .okta-form-label {
  /* -- Fonts and Text Colors -- */
    font-size: $font-size !important;
   
    color: $medium-text-color;
  }

  .labelClass, label {
    /* -- Fonts and Text Colors -- */
    font-size: var(--bs-body-font-size) !important;
    font-weight: 700 !important;
    color:$text-primary-color !important;
    padding-bottom: 5px;
      &[id="loginForm.securityQuestion.label"] {
        /* -- Fonts and Text Colors -- */
        font-size: var(--bs-body-font-size) !important;
        font-weight: 700 !important;
        color:$text-primary-color !important;
          float: left;
          text-align: left;
          margin-top:10px;
      }

      &[id="loginForm.securityAnswer.label"] {
        /* -- Fonts and Text Colors -- */
          font-size: $font-size;
          font-weight: bold;
          color: $medium-text-color;
          float: left;
          text-align: left;
          margin-top:10px;
          width: 100%;
      }

      &[id="loginForm.customSecurityQuestion.label"] {
        font-size: $font-size;
        font-weight: bold;
        color: $medium-text-color;
        float: left;
        text-align: left;
        margin-top:5px;
        width: 100%;
      }
  }

  .okta-sign-in{
    margin:0px !important;
    @media(min-width:20px) {
     // border: 1px solid #bbb;
      margin:0px;

  }
  
  }


// .country-list{
//   background-color: white;
//   width:265px
// }
  .link {
  /* -- Fonts and Text Colors: links -- */
    font-size: var(--bs-body-font-size) !important;
   
    //color: $medium-text-color;
    color: $link-text-color !important;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500 !important;
    // &:visited,
    &:hover,
    &:active {
    /* -- Fonts and Text Colors: links -- */
     color: #164f61 !important;
      
      //float: inherit; 
    }
    &.js-unlock{
      display:none !important;
    }
    &:focus:not(:disabled) {
      outline: 0.125rem dotted $dark-text-color;
      outline-offset: 0.188rem;
      box-shadow:unset;
     }
  }
  .js-sso-link {
    display: flex;
    margin-top: 12px;
    width: max-content;
    &:focus-visible,:focus {
      box-shadow: 0 0 8px #51cbee;
    }
  }

  .inline-link {
  /* -- Fonts and Text Colors: links -- */
    font-size: $font-size;
    color: $link-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
    /* -- Fonts and Text Colors: links -- */
      font-size: $font-size;
      color: $link-text-color;
    }
  }

  .custom-checkbox {
    position: relative;
    clear: both;
 
    label {
      font-size: var(--bs-body-font-size) !important;
      font-weight: 400 !important;
      color:$text-primary-color !important;
      float: left;
      text-align: right;
      background:unset !important;
      padding-left:30px !important;
      &.checked{
        &:after{
          font-family: $font-family-material-icon !important;
          content: "\e5ca";
          font-weight: normal;
          font-style: normal;
          font-size: 1.3rem;
          line-height: 1;
          letter-spacing: normal;
          color:#fff;
          position:absolute;
          top:3px;
          left:0px;
        }
        &:before{
          background:$text-primary-color;
        }
      }
      &:before{
          content:"";
          width: 1.25rem;
          height: 1.25rem;
          position:absolute;
          top:3.5px;
          left:0px;
          vertical-align: top;
          border: 1px solid $text-primary-color;
          border-radius:4px;
      }
    }

    // label[for="input7"] {
    //   /* -- Fonts and Text Colors -- */
    //     font-size: $font-size;
    //     font-weight: bold;
    //     color: $medium-text-color;
    //     float: left;
    //     text-align: right;
    // }

    // label[for="input42"] {
    //   /* -- Fonts and Text Colors -- */
    //     font-size: $font-size-medium !important;
    //     font-weight: 100;
    //     color: $color-gray-text;
    //     float: left;
    //     text-align: right;
    // }
  

    input[type="checkbox"] {
        &:hover,
        &:focus,
        &:checked {
          font-weight: 400;
          float: right;
          text-align: left;
          top: 7px;
          margin: 0;
          &:after{
            font-family: $font-family-material-icon !important;
            content: "\e5ca";
            font-weight: normal;
            font-style: normal;
            font-size: 1.3rem;
            line-height: 1;
            letter-spacing: normal;
            color:#fff;
            position:absolute;
            top:3px;
            left:0px;
          }
          &:before{
            background:$text-primary-color;
          }
        }
        &:focus{
          + label{
            &:before{
              
                outline: 0.125rem dotted $dark-text-color;
                outline-offset: 0.188rem;
              
            }
          }
        }
    }
  }

  .password-reset-para-div,
  .password-expired-para-div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .o-form-input-name-countryCode {
    font-size: $font-size !important;
   
    margin-top: 30px;
  }
  
.o-form-has-errors .infobox-error {
    font-size: var(--bs-body-font-size) !important;
    display: block!important;
    color:$text-primary-color !important;
    font-weight: 400;
    margin-top:12px;
    margin-bottom: 10px;
    border-color:$error-pink-color !important;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fcf7fb;
    -webkit-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset !important;
    &:before{
      background:transparent;
    }
    div{
      font-size: var(--bs-body-font-size) !important;
      color:$text-primary-color
    }
    .error-16:before{
      background-image: url(../images/error-icon.svg);
      background-repeat: no-repeat;
      background-size: 20px 20px;
      height: 22px;
      width: 22px;
      position: relative;
      top: 1px;
      left: 1px;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      color:$error-pink-color !important;
      @include media-sm-max {
        font-size: 1.3rem;
      } 
    }
    .color-pink{
      color:$error-pink-color;
    }
    .link-error{
      a{
        color:$text-primary-color !important;
        font-size: var(--bs-body-font-size) !important;
        text-decoration:underline;
        display:inline-block;
        letter-spacing: 0.01px;
        margin-top:0.5rem;
        &.mr-2{
          margin-right:1.5rem;
        }
      }
    }
}

.o-form-input-group .o-form-control:last-child {
  top:20%;
}

.okta-form-infobox-warning {
  margin-top:40px;
}

.enroll-call-phone {
  width:160px;
}

.enroll-call-extension {
  width:90px;
}

.o-form-input-name-phoneExtension {
  margin-top:7px;
}

.o-form-input-passcode{
  font-size: $font-size !important;
 
  height: 30px;
  width: 250px;
}
  .o-form input {
    font-size: var(--bs-body-font-size) !important;
   

    &[id="loginForm.securityAnswer"] {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      background-color: #fff;
      border-color: #bbb;
      top: -5px;
      height: 25px;
      border: 1px solid #bbb;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 6px 8px;

    }

    &[id="loginForm.customSecurityQuestion"] {
      width: 100%;
      margin-top: 5px;
      background-color: #fff;
      border-color: #bbb;
      top: -5px;
      height: 25px;
      border: 1px solid #bbb;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 6px 8px;
    }
  }

  .o-form-explain.okta-form-input-error {
    padding-top: 0px;
    padding-bottom: 10px;
    width: 100% !important;
    color:$error-pink-color !important;
    font-weight: 400 !important;
    font-size: 14.2px !important;
    word-break: break-word !important;
    hyphens: unset !important;
    &.top-2{
      position:relative;
      top:5px;
    }
  }

  .o-form-input-error .icon-16 {
    left: -2px;
    position: absolute !important;
    top: 0px;
  }

  .o-form-theme {
    padding: 0 0 0;
  }


  .o-form-button-bar {
    padding: 0 0 0px;
    margin:0px;
    margin-top:2.5rem;
  }
  
  .focused-input {
    box-shadow: 0 0 0px rgb(204,204,204) !important;
  }

  &.main-container {
  /* -- Main Background and Border Colors -- */
    margin-top: 75px;
    //background-color: $primary-bg-color;
    background-color:#ffffff;
    border: none;
    height:max-content !important;
    @media (max-width:576px) {
      margin:0px;
    }
//    border-color: rgb(204,204,204);

  //  box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
    // width: 535px;
    // height:688px;
   // margin-left: $container-margin-left;
   // height: auto;
    //border:none;

    .margin-btm-5 {
      margin-bottom:0px;
      top: -5px;
      position: relative;
    }

    .margin-btm-30 {
      margin-bottom:0px;
      top: -8px;
      position: relative;
    }

    .margin-btm-0 {
      margin-bottom:0px;
      top: 0px;
      position: relative;
    }
    /*
    .margin-top-30 {
      top: -12px;
      position: relative;
      padding: 0px !important;
      color: #fbfbfb;
      width: 120px !important;
      left: 10px;
      margin-top:38px;
    }
    */

    .margin-top-30 {
      top: 0px;
      position: relative;
      padding: 0px !important;
      left: 0px;
      margin-top:0px;
      &.margin-top-30-custom{
        margin-bottom: 1rem !important;
      }
    }

  }

  .auth-content 
  {
    .link.js-help{
      display:none !important;
    }
    .help-links {
    display:block !important;
    margin-top:4px;
   li {
     margin-top: 10px;
     cursor: pointer;
   }
  }
}

  // .okta-sign-in-header {
  // /* -- Main Background and Border Colors -- */
  //   border-bottom-color: #ddd;
  //   padding-bottom: 15px;
  //   padding-top: 15px;
  // }


  .auth-org-logo {
		max-width: 212px;
    width:100%;
    max-height: max-content;
    margin-bottom: 2.5rem;
    position: relative;
    margin-left: -47px;
    left:-2px;
    display: block;
    @include media-lg-max {
      max-width: 210px;
    }
    @include media-sm-max {
      margin:0 auto;
      max-width: 158px;
      left: -20px;
  } 
		//position: relative;
  	//left: -80px;
	}

  .auth-content {
    padding: 0;
    border: 1px solirgb(61, 7, 7);
    @include media-sm-max {
      max-width: fit-content;
      padding:0px 20px !important;
  } 
   
   
  }


  .auth-content-inner {
    /* -- Main Background and Border Colors -- */
    position: relative;
  	// top: -15px;
    // right: -5px;
  }

  .okta-sign-in-beacon-border {
  /* -- Main Background and Border Colors -- */
    border-color: #a7a7a7;
  }

  .okta-form-divider {
  /* -- Main Background and Border Colors -- */
    border-bottom-color: #e8e8e8;
  }

  .factor-icon,
  .qrcode-image {
  /* -- Main Background and Border Colors -- */
    border-color: #ccc;
  }

  .auth-beacon {
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
  }

  .enroll-totp .o-form-label legend {
    color: #777;
    margin-top: 25px;
}

  .mfa-okta-verify-30,
  .enroll-factor-row .mfa-okta-verify {
    /* -- Factor Icons (small): Okta Verify -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_ico@2x.png');
    }
  }

  .mfa-okta-verify {
    /* -- Factor Icons (large): Okta Verify -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify@2x.png');
    }
  }


  .mfa-okta-sms {
    /* -- Factor Icons (large): SMS -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/sms_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/sms@2x.png');
    }
  }

  .mfa-call-30,
  .enroll-factor-row .mfa-okta-call {
    /* -- Factor Icons (small): CALL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_ico@2x.png');
    }
  }

  .mfa-okta-call {
    /* -- Factor Icons (large): CALL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall@2x.png');
    }
  }
  
  .mfa-email-30,
  .enroll-factor-row .mfa-okta-email {
    /* -- Factor Icons (small): EMAIL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_ico@2x.png');
    }
  }

  .mfa-okta-email {
    /* -- Factor Icons (large): EMAIL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email@2x.png');
    }
  }

  .mfa-question-30,
  .enroll-factor-row .mfa-okta-security-question {
    /* -- Factor Icons (small): Security Question -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_ico@2x.png');
    }
  }

  .mfa-okta-security-question {
    /* -- Factor Icons (large): Security Question -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question@2x.png');
    }
  }

  .mfa-okta-password {
    /* -- Factor Icons (large): Password -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/password_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Password -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/password@2x.png');
    }
  }

  .button {
  /* -- Buttons' Colors -- */
    font-size: var(--bs-body-font-size) !important;
    font-weight: 500 !important;
     background: $button-bg-color;
    height:max-content !important;
    min-height: 50px;
    line-height: 16px;
    margin-bottom: 1.5rem !important;
    width: 100% !important;
    left:0 !important;
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: var(--bs-white);
   font-family: $font-family-body !important;
   border: 1px solid $button-bg-color;
   &:not(:disabled){
    cursor: pointer;
   }
   &.margin-bottom-0{
     margin-bottom: 0px !important;
   }
   &.button-secondary{
     color: $text-primary-color;
     border-color: $text-primary-color;
     background:$button-text-color;
     &:focus,&:hover,&:active {
      background: #f4f6f6 !important;
      border-color: $text-primary-color !important;
    }
   }
    &:focus,&:hover,&:active {
      background: #b22233 !important;
      border-color: #b22233 !important;
    }
    &.btn-disabled,&.link-btn-disabled{
      color: #b8bbba !important;
      background: #f4f6f6 !important;
      border-color: #f4f6f6 !important;
      &:focus,&:hover,&:active {
        color: #b8bbba !important;
        background: #f4f6f6 !important;
        border-color: #f4f6f6 !important;
      }
     
    }
    &:focus:not(:disabled) {
      outline: 0.125rem dotted $dark-text-color;
      outline-offset: 0.188rem;
     }
  }
  .button-resend-passcode{
    @include dark-button-template($primary-button-bg-color);
    //background: #e41f35;
    //border-color: #e41f35;
    font-size: $button-font-size !important;
   
    height: 45px;
    line-height: 16px;
    //margin-bottom: 28px !important;
    width: 290px !important;
    left: 1.5%;
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: $button-text-color;

    &:focus {
      border-color: $input-border-color-focus;
    }

  }
  .button-dark {
    /* -- Buttons' Colors -- */
    @include dark-button-template($dark-button-bg-color);
  }

  .button-primary {
  /* -- Buttons' Colors -- */
  //  @include dark-button-template($primary-button-bg-color);
    font-weight: 600;
    left: -1px;
  }

  .button-success {
  /* -- Buttons' Colors -- */
    @include dark-button-template($success-button-bg-color);
  }

  .button.link-button {
    padding: 15px 5px 5px 5px !important; // added for button resend
    /* -- Buttons' Colors -- */
    &:hover,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
      //background-color: antiquewhite;
    }
  }

  .button-custom {

    @include light-button-template($button-bg-color);
    font-size: $button-font-size !important;
   
    height: 45px;
    line-height: 16px;
    margin-bottom: 8px !important;
    width: 290px !important;
    left: 1%;
    position: relative;
    text-align: center;
    vertical-align: middle;

    &:focus {
      border-color: $input-border-color-focus;
    }
    padding: 15px 5px 5px 5px !important; // added for button resend
   
      cursor: default;
      background-image: none;
      background-color: #e41f35;
      // background: -ms-linear-gradient(top, #fd0000 0%, #ee0000 100%);
      // background-image: linear-gradient(#fd0000, #ee0000);
      border-color: darkred;
      //border-bottom-color: #860000;
      text-decoration: none;
      box-shadow: none
      
  }
    
  .button.link-button-custom-enabled {
    @include light-button-template($button-bg-color);
    font-size: $font-size !important;
   
    height: 45px;
    line-height: 16px;
    margin-bottom: 8px !important;
    width: 290px !important;
    left: 1%;
    position: relative;
    text-align: center;
    vertical-align: middle;

    &:focus {
      border-color: $input-border-color-focus;
    }
    @include dark-button-template($primary-button-bg-color);
    padding: 15px 5px 5px 5px !important; // added for button resend
    cursor: default;
    background-image: none;
    background-color: red;
    border: rgb(197, 191, 191);
    
  }

 


  .special-label{
    display: none;
  }

  // .flag-dropdown{
  //   display:none
  // }

  // .selected-flag{
  //   display:none
  // }
  #okta-signin-submit,#custom-primary-id{ // for Login-Password reset- Confirm password button
    &:disabled{
      padding: 0 5px !important;
      border-color: #f4f6f6 !important;
    }
  }
  .button.link-button-disabled {
    padding: 15px 5px 5px 5px !important; // added for button resend
    /* -- Buttons' Colors -- */
    color: $color-gray-text;
    border:solid 1px #ccc;
    background: #cccccc !important;
    &:hover,
    &:focus,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }
  .button.button-secondary.link-button-disabled{
    padding: 15px 5px 5px 5px !important;
    background: #f4f6f6 !important;
    border-color: #b8bbba !important;
    color: #b8bbba !important;
    box-shadow: none;
    &:hover,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;

      color: #b8bbba !important;
      background: #f4f6f6 !important;
      border-color: #b8bbba !important;
    }
  }
 

 
  button.button-primary.link-button-disabled {
    padding: 15px 5px 5px 5px !important; // added for button resend
    /* -- Buttons' Colors -- */
    color: #b8bbba !important;
    background: #f4f6f6 !important;
    border-color: #f4f6f6 !important;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;

      color: #b8bbba !important;
    background: #f4f6f6 !important;
    border-color: #f4f6f6 !important;
    }
  }

  .icon-button {
  /* -- Buttons' Colors -- */
    @include button-template($icon-button-bg-color, 0%, $dark-text-color, 23%, 26%);
  }

  .okta-dropdown-list {
  /* -- Dropdown Colors -- */
    /* background: #fff; */
    border-color: $input-border-color-hover;
    width: 250px;
  }

  .factors-dropdown-wrap .okta-dropdown-list {
  /* -- Dropdown Colors -- */
    // scss-lint:disable DuplicateProperty
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    // scss-lint:disable DuplicateProperty
  }

  .enroll-factor-row .enroll-factor-button .button {
    position: absolute;
    left: 10%;
    color: #fff;
    height: 25px;
    line-height: 22px;
    margin-top: -5px;
  }

  .enroll-factor-row .enroll-factor-description {
    font-size: $font-size !important;
   
    width: 100%;
    overflow: hidden;
    margin-left: -10px;
    margin-top: -5px;
  }

  .enroll-factor-row .enroll-factor-label {
    margin-left: -8px;
    padding-top: 5px;
  }

  .enroll-factor-row .enroll-factor-icon-container {
    padding-top: 5px;
  }

  .okta-dropdown-option {
  /* -- Dropdown Colors -- */
    /* background: transparent; */
    /* border-bottom-color: #fff; */

    &:hover {
    /* -- Dropdown Colors -- */
      /* background: #f9f9f9; */
    }

    &.dropdown-list-title:hover {
    /* -- Dropdown Colors -- */
      background: transparent;
    }

    a {
    /* -- Dropdown Colors -- */
      color: $dark-text-color;
    }
  }

  .form-label-without-margin-top {
    font-size: $font-size !important;
   
  }

  .o-form-label,
  .o-form-explain {
    
    font-size: var(--bs-body-font-size) !important;
    font-weight: 700 !important;
    color:$text-primary-color !important;

    &[id="input-container-welcom-error"] {
        margin-bottom: 10px ;
      }
  }

  

  .welcome-new {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .infobox-error-welcome {
    margin-top: -10px;
    margin-bottom: 15px !important;
  }

  .welcome-form-has-errors{
    width: 100%;
  }

  .o-form-fieldset:last-child {
   //margin-bottom: 5px;
   // top: -20px;
    position: relative;
    margin-bottom: 0px;
  }

  .passcodeInputbox{
    
    font-size: $font-size !important;
  /* -- Input Fields -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;
    top: -3px;
    height: 30px;
    width: 250px; // added for passcode button...
    padding: 6px 8px;
    line-height: 16px;
    box-sizing: border-box;
  }
  .o-form-fieldset-passcode{
    display: none;
    
    font-size: $font-size !important;
  /* -- Input Fields -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;
    top: -3px;
    height: 30px;
    width: 250px;
    //margin-top:80px
  }

  .infobox {
    font-size: $font-size;
    
    margin-bottom: 0px;
  }
  

  .reactivation-hint,
  .mobile-recovery-hint {
    margin-bottom: 0px;
    padding-bottom: 10px;
    position: relative;
    top: 0px;
    color:$color-gray-text;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    &.mobile-recovery-hint{
      font-size: var(--bs-body-font-size);
      color: $dark-text-color;
      float: left;
    }
  }
  

  .okta-infobox-error {
  /* -- Error Infobox Colors -- */
    /* background-color: #fff; */
    /* border: 1px solid #ddd; */
    font-size: $font-size;
    
    margin-bottom: 0px;

    &:before {
    /* -- Error Infobox Colors -- */
      /* background-color: #e34843; */
      font-size: $font-size;
      
    }

    .error-16:before {
    /* -- Error Infobox Colors: error icon -- */
      /* color: #fff; */
      font-size: $font-size;
      
      /* Use the rules below to replace an error icon in error infoboxes */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }

  }

  .enroll-required-factor-list {
    .enroll-factor-row-min .enroll-factor-description {
      h3 {
        /* -- Enroll required factors, factors that are not the current step -- */
        color: $light-text-color;
      }
    }
  }

  .k-input-button{
    width: 250px;
    
    font-size: $font-size !important;
    background-color: $input-bg-color;
    border-color: $input-border-color;
    height: 30px;
  }

  .k-dropdownlist{
    border-color: $input-border-color-hover;
    width: 250px;
    
    font-size: $font-size !important;
    background-color: $input-bg-color;
    height: 30px;
  }
  .okta-form-input-field {
    
    font-size: $font-size-medium + 2 !important;
  /* -- Input Fields -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;
    // top: -3px;
    margin: 5px 0 8px;
    height: max-content;
    width: 100%; // added for passcode button...

    &:hover {
    /* -- Input Fields -- */
      border-color: $input-border-color-hover;
    }

    &.focused-input {
      border-color: $input-border-color-focus;
    }

    input {
    /* -- Input Fields -- */
      
      font-size: $font-size + 1;
      color: $dark-text-color;

      &::-webkit-input-placeholder {
      /* -- Input Fields: placeholder -- */
        
        font-size: $font-size + 1;
        color: $placeholder-text-color;
      }

      &::-moz-placeholder {
      /* -- Input Fields: placeholder -- */
        
        font-size: $font-size;
        color: $placeholder-text-color;
      }

      &:-ms-input-placeholder {
      /* -- Input Fields: placeholder -- */
        
        font-size: $font-size;
        color: $placeholder-text-color;
      }

      &[disabled] {
      /* -- Input Fields: disabled input field -- */
        color: $medium-text-color;
        -webkit-text-fill-color: $medium-text-color;
        /* background: #f4f4f4; */
      }

      &[type="password"] {
      /* -- Input Fields: password input field -- */
        
        font-size: $font-size;
      }
    }

    // placeholder {
    // /* -- Input Fields: placeholder -- */
    //   
    //   font-size: $font-size;
    //   color: $placeholder-text-color;
    // }
  }

  .okta-form-input-dropdown{
    
    font-size: $font-size !important;
  /* -- Input Fields -- */
    background-color: $input-bg-color;
  //  border-color: $input-border-color;
    top: -3px;
    height: 30px;
    width: 250px; 
  }

  .dropdown-item{
    background-color:white;
    text-align: center;
    border-color: #bbb;
    width: 250px;
    height: 30px;
  }


  .dropdown-basic-button{
    content:"▼";
    font-size: 12.5px !important;
    
    width: 250px;
    inline-size: 100%;
    overflow-wrap: break-word;
    height: 30px;
    background-color:white;
    padding: 6px 8px;
    border-color: #bbb;

  }

  .o-form select {
    font-size: 12.5px !important;
    
    width: 100%;
    inline-size: 100%;
    overflow-wrap: break-word;
    height: 30px;
    padding: 6px 8px;
    border-color: #bbb;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    background-color:white;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding-right: 2rem;
    padding-left: 1rem;

}

.form-control{
  width:100%;
  height:max-content;
  border-radius: 4px;
  border: solid 1px $dark-text-color;
  min-height:52px;
  letter-spacing: 0.03px;
  text-align: left;
  color:$text-primary-color;
  font-size:var(--bs-body-font-size) !important;
  font-family:$font-family-body !important;
  inline-size: 100%;
  overflow-wrap: break-word;
  padding: 6px 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  background-color:white;
  margin-right: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;

}
.country-list{
  background-color: white;
  width:314px;
  left: -10px;
  overflow-y: auto !important;
  top: 48px;
  box-shadow: 0 0.188rem 0.375rem 0 rgba(0,0,0,.15);
  max-height: 300px !important;
  border-radius: 0px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #6b7070;
  }
  &::-webkit-scrollbar-track {
    background:#e2e9ea;
  }
  li{
    padding: 1rem;
    border:1px solid #e2e9ea;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: .888rem;
    &:last-child{
      border-bottom: 1px solid #ffffff;
    }
    .country-name{
      color: $text-primary-color !important;
    }
    .dial-code{
      margin-right: 6px;
      color: $text-primary-color !important;
    }
    &.highlight{
      background:$text-primary-color;
      border-color: $text-primary-color;
      .country-name,.dial-code{
        color: #fff !important;
      }
    }
    &:hover{
      // background:$text-primary-color;
      // color: #fff;
      border-color: $text-primary-color;
      // .country-name,.dial-code{
      //   color: #fff !important;
      // }
    }
  }
}
.inputWidth{
  width:100%;
  height:max-content
}

#passcodeInputbox{
  width:100%;
  margin-top:5px;
  margin-bottom: 0px;
  height:max-content;
}
.okta-form-input-field-customUi {
  
 // background: linear-gradient(to right, #888 50%, white 50%);
  //background: linear-gradient(90deg, #ddd 20%, white 0%);
  //background:#e0e4ea;
  width:65px;
  height:40px
}


.okta-form-input-field-customUi-phone{
  background: white;
  width:calc(100% - 0px);
  height: max-content;
  min-height:52px;
  position:relative; 
  right:0px; 
  bottom:0px;
  padding: 0px 0px;
  border-radius: 4px;
  border: solid 1px $dark-text-color;
  letter-spacing: 0.03px;
  text-align: left;
  color:$text-primary-color;
  font-size:var(--bs-body-font-size) !important;
  font-family:$font-family-body !important;
  margin-top:0px !important;
  .react-tel-input{
    .form-control{
      // border-left:0px !important;
      // border-top-left-radius:0px !important;
      // border-bottom-left-radius:0px !important;
      padding-left: 86px !important;
      &:hover{
        box-shadow: inset 0rem 0rem 0rem 0.1rem $text-primary-color;
        border-color: $text-primary-color;
      }
      &:focus{
        // outline: 0.125rem dotted #6b7070;
        outline: none !important;
        // outline-offset: 0.313rem;
      }
    }
  }
}

.okta-form-input-field-custompasscode{
  display: none;
 // margin-bottom:80px
 
}
.o-form-input.o-form-has-errors.custom-passcode{
  margin-bottom:0px
}
#passcodeInputbox{
  margin-bottom:00px
}
#passcodeInputbox-voice{
  margin-bottom:20px
}
#passcodeInputbox-text{
  margin-bottom:20px
}
.operations-supplierCapacity1{
  float:left;
  width:10px
}

#resendPasscode {
  padding: 0px 10px !important;
  margin-top: 1.5rem !important;
  left:0px;
}
.o-form .input-fix input[readonly] {
box-shadow: unset;
height: max-content;
bottom: 0px;
}

.o-form-input-name-username-custom{
float:left;
width:10px
}
.hidesendpasscode{
  display: none;
}

.show{
  display: block;
}
  .show>.dropdown-menu {
    display: block;
    position: absolute;
    background-color:white;
    text-align: center;
    border-color: #bbb;
    width: 250px;
    height: 30px;
  }

  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left:8em;
    content: "";
    border-top: 0.3em solid;
   
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: black;
   
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

  .k-i-loading.k-example-loading {
    font-size: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(2,117,216);
}
  .input-icon:before,
  .input-tooltip:before {
  /* -- Input Fields: icons -- */
    color: $light-text-color;
    
    font-size: $font-size;
  }

  .okta-form-label-inline {
  /* -- Input Fields -- */
    /* background-color: #f2f2f2; */
    /* border-color: #c8c8c8; */
    /* color: #999; */
    
    font-size: $font-size;
  }

  .okta-form-input-error {
  /* -- Input Fields: error highlight -- */
    /* color: #d93934; */
  
    margin-left: 0px;
    margin-bottom: 0px;

    .error-16-small:before {
      background-image: url(../images/error-icon.svg);
      background-repeat: no-repeat;
      background-size:13px 13px;
      height: 13px;
      width: 13px;
      position: relative;
      top: 1px;
      left: 1px;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      color:$error-pink-color !important;
    /* -- Input Fields: error icon -- */
      /* color: #e34843; */

      /* Use the rules below to replace input fields error icon */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }
  }

  .chzn-container-single .chzn-single div b {
    margin-top: -5px;
  }

  .chzn-single {
  /* -- Input Fields: Select Input -- */
    
    font-size: $font-size;
    background-color: $input-bg-color;
    border-color: $input-border-color;
    height: 27px;
    line-height: 25px ;

    span {
    /* -- Input Fields: Select Input -- */
      color: $medium-text-color;
    }

    &:hover {
    /* -- Input Fields: Select Input -- */
      border-color: $input-border-color-hover;
    }
  }

  .chzn-with-drop {
    .chzn-single,
    .chzn-drop {
    /* -- Input Fields: Select Input -- */
      background-color: $input-bg-color;
      border-color: $input-border-color-hover;
      height:27px;
    }

    .chzn-results {

      li {
      /* -- Input Fields: Select Input -- */
        /* border-color: #fff; */
      }

      .highlighted {
      /* -- Input Fields: Select Input -- */
        /* background-color: #f9f9f9; */
      }
    }
  }

  .primary-auth { /* -- Page specific top level selectors -- */ }
  .password-expired { /* -- Page specific top level selectors -- */ }
  .password-reset { /* -- Page specific top level selectors -- */ }
  .password-reset-email-sent { height: 300px /* -- Page specific top level selectors -- */ }
  .forgot-password { /* -- Page specific top level selectors -- */ }
  .account-unlock { /* -- Page specific top level selectors -- */ }
  .account-unlock-email-sent { /* -- Page specific top level selectors -- */ }
  .recovery-challenge { /* -- Page specific top level selectors -- */ }
  .recovery-loading { /* -- Page specific top level selectors -- */ }
  .recovery-question { /* -- Page specific top level selectors -- */ }
  .refresh-auth-state { /* -- Page specific top level selectors -- */ }

  .enroll-choices { /* -- Page specific top level selectors -- */ }
  .enroll-totp { /* -- Page specific top level selectors -- */ }
  .barcode-totp { /* -- Page specific top level selectors -- */ }
  .activate-totp { /* -- Page specific top level selectors -- */ }
  .enroll-manual-totp { /* -- Page specific top level selectors -- */ }
  .barcode-push { /* -- Page specific top level selectors -- */ }
  .activate-push { /* -- Page specific top level selectors -- */ }
  .enroll-manual-push { /* -- Page specific top level selectors -- */ }
  .enroll-activation-link-sent { /* -- Page specific top level selectors -- */ }
  .enroll-symantec { /* -- Page specific top level selectors -- */ }
  .enroll-rsa { /* -- Page specific top level selectors -- */ }
  .enroll-onprem { /* -- Page specific top level selectors -- */ }
  .enroll-duo { /* -- Page specific top level selectors -- */ }
  .enroll-sms { 
    .enroll-sms-phone {
    width:140px;
    margin-top: -16px;
    }
  }
  .enroll-call { /* -- Page specific top level selectors -- */ }
  .enroll-question { /* -- Page specific top level selectors -- */ }

  .mfa-verify-duo { /* -- Page specific top level selectors -- */ }
  .mfa-verify { /* -- Page specific top level selectors: all challenge pages except duo -- */ }
  .mfa-verify-totp { /* -- Page specific top level selectors -- */ }
  .mfa-verify-push { /* -- Page specific top level selectors -- */ }
  .mfa-verify-totp-inline { /* -- Page specific top level selectors -- */ }
  .mfa-verify-question { /* -- Page specific top level selectors -- */ }
  .mfa-verify-passcode { /* -- Page specific top level selectors -- */ }
  .mfa-verify-password { /* -- Page specific top level selectors -- */ }

}
#okta-sign-in .auth-footer {
  font-size: $font-size;
  
  padding-top: 5px;
  &.auth-footer-custom{
    line-height: 1.2;
  }
}

.auth-footerNew {
  margin-top: -1rem !important;
}

#okta-sign-in p {
  font-size: var(--bs-body-font-size);
  font-weight:400;
  hyphens: manual;
  padding-bottom:5px;
  color:$text-primary-color;
  font-family:$font-family-body !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}


#custom-para-p,#custom-para-p-1,#custom-para-p-2,#custom-para-p-3,#custom-para-p-4,#custom-para-p-5,#custom-para-p-6,#custom-para-p-7,
#custom-para-p-8 ,#custom-para-p-9 , #custom-para-p-10, #custom-para-p-11, #custom-para-p-12, #custom-para-p-13, #custom-para-p-14 {
  font-size: var(--bs-body-font-size);
    hyphens: manual;
    color:$color-gray-text !important; 
    padding-bottom:0px !important;
    padding-top: 5px !important;

}

#custom-para-fifth-p {
 
  font-size: $font-size-medium !important;
  hyphens: manual;
  color:$color-gray-text !important;
  padding-bottom:15px !important;
  padding-top: 5px !important;
}

#custom-para-p-medium {
  
  font-size: $font-size-medium + 2!important;
  font-weight:700 !important;
  hyphens: manual;
  color:$color-gray-text !important; 
  padding-bottom:15px !important;
  padding-top:15px !important;
}

// #custom-button{
//   margin-top:-25px;
// }

// .phone-enrollment{
//   margin-top: -25px !important;

// }

#okta-sign-in a {
 
  font-size: $font-size-medium !important;
  hyphens: manual;
  color: #4875d0;
}

#okta-sign-in div {
 
  font-size: 18px;
  color:black;
  &.beacon-container{
    position:static;
    transform:unset;
    width:100%;
    height:max-content;
  }
  &.auth-content{
    h1,h6{
      color:$text-primary-color;
    }
    h1{
      font-size: 36.5px;
      line-height: 1.21;
      letter-spacing: 0.04px;
      font-weight: 500;
      max-width:270px;
        @include media-xl-max {
              font-size: 25.6px;
              max-width:100%;
          } 
    }
    h6{
      // font-size: 16px;
      font-weight: normal;
      margin-top: 4px;
      margin-bottom: 1.1rem;
      line-height: 1.5;
      letter-spacing: 0.03px;
      font-size: var(--bs-body-font-size);
    }
  }

}

#okta-sign-in {
  width: auto !important;
  overflow: unset;
}

.okta-sign-in-tooltip.qtip-custom {
/* -- Tooltips -- */
  
  font-size: $font-size;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
  /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }

  .qtip-titlebar {
  /* -- Tooltips -- */
    /* background-color: #404040 */
  }
}

.okta-sign-in-security-image-tooltip.qtip-custom {
  /* -- Tooltips -- */
  
  font-size: $font-size;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
    /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }
}

footer {
	position:relative;
//   @media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
//     text-align: center;
  
// }
}

p.copyright {
	position: relative;
	width: 50%;
  color: black;
  line-height: 3px;
  font-size: 0.7em;
	text-align: right;
	bottom:0;
  @media (max-width:629px) {
    text-align: center;  
}
}

#okta-sign-in * {
 // color: red;
  
}


#okta-sign-in .auth-footer .goto {
  float: left;
}

// #custom-title-txt,#forgot-password-title-txt {
//   color: black;
//   font-size: 20px !important;
//   font-weight: bold !important; 
//   clear: both;
  
// }
.heading-inner-page,.sub-heading-inner-page,#custom-title-txt,#custom-title-hr,
#okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3{
  color:$text-primary-color;
}
.heading-inner-page,#custom-title-txt,#okta-sign-in h2, #okta-sign-in h3{
  font-size:$font-size-heading !important;
  font-weight:500  !important;
  padding-bottom: 8px !important;
  max-width:100% !important;
  line-height: 1.1 !important;
  letter-spacing: 0.05px;
  @include media-xl-max {
    font-size:20.3px !important;
  }
}

#custom-title-txt.password-expiry-soon-title {
  @include media-xl-min {
    display: inline-block; 
    width: 454px; 
    max-width: 454px !important;
  }
}

.requirements-list {
  width: 100%;
}

.first-line {
  margin-top: 10px;
  margin-bottom: -15px !important;
}

.sub-heading-inner-page,#custom-title-hr{
  font-size:$font-size;
  font-weight:400;
}

.disabled-button{
  background-color:#e41f35;
}
.enabled-button{
  padding: 10px 5px 5px 5px !important; // added for button resend
  /* -- Buttons' Colors -- */
  // &:hover,
  // &:focus,
  // &:active,
  // &:focus {
  /* -- Buttons' Colors -- */
    cursor: default;
    background-image: none;
    background-color: red;
  //}
}

.passwordResetParaDivLeft {
  font-size: var(--bs-body-font-size);
    hyphens: manual;
    color:$color-gray-text !important; 
    padding-bottom:0px !important;
    padding-top: 5px !important;
    margin-bottom: -8px !important;
}

#custom-title-info-italic {
  color: black;
  font-style: italic !important;
  padding-top: 8px !important;
}

#custom-title-info-left, #custom-title-info-left2 {
  color: black;
  padding-top: 8px !important;
  text-align: left;
}

#okta-sign-in #forgot-username-retrieve-link,.mobile-recovery-hint a {
  color: $text-primary-color !important;
  text-decoration: underline !important;
  font-weight:500 !important;
  font-size: var(--bs-body-font-size) !important;
} 

#custom-title-hr {
  display: block;
  height: 1px;
  //border: 0;
  //margin: 1em;
  width:528px;
  margin-left: -100px;
  padding: 0;
  opacity:0.25;
  //border: solid 1px #ccc;
 // border-color:#aaa;
}

#custom-title-hr-thin {
  display: block;
  height: 0.1px;
  //border: 0;
  //margin: 1em;
  width:528px;
  margin-left: -100px;
  padding: 0;
  opacity:0.25;
  //border: solid 1px #ccc;
 // border-color:#aaa;
}

#custom-title-hr2 {
  display: block;
  height: 1px;
  //border: 0;
  //margin: 1em;
  width:528px;
  margin-left: -100px;
  padding: 0;
  opacity:0.25;
  //border: solid 1px #ccc;
  //border-color: #aaa;
}

#okta-sign-in .forgot-password-sms-enabled .o-form-fieldset-container {
  overflow: initial;
}

// #forgot-password-title-txt {
//   color: black;
//   font-size: 18px !important;
//   font-weight: bold !important; 
//   clear: both;
//   padding-bottom: 8px !important;
// }
#forgot-password-title-txt-small, #forgot-password-title-txt-small-call {
  color: black;
  font-size: $font-size !important;
  font-weight: bold !important; 
 
  clear: both;
  padding-bottom: 8px !important;
}

#okta-sign-in .o-form .input-fix input[disabled]{
  color: #777;
  border-radius: 3px;
}

#okta-sign-in li {
  
  font-size: $font-size !important;
  
  

}

.text-msg-auth{
  margin-top:-20px
}

.country-name{
  
  font-size: $font-size !important;
  font-weight:100;
}

.dial-code{
  
  font-size: $font-size !important;
  font-weight:100;
}
.custom-para{
  margin-top:-30px !important;
}

#forgot-password-title-hr {
  display: block;
  height: 1px;
  border: 0;
  //border-top: 1px solid #ccc;
  //margin: 1em 0;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0;
}

#passcode-title-txt {
  color: black;
  font-size: 18px !important;
  font-weight: bold !important; 
  clear: both;
  padding-bottom: 8px !important;
}

#passcode-title-info {
  color: black;
  padding-top: 8px !important;
}

#passcode-title-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

#password-reset-title-txt {
  color: black;
  font-size: 18px !important;
  font-weight: bold !important; 
  clear: both;
  padding-bottom: 8px !important;
}

#password-reset-title-info {
  color: black;
  padding-top: 8px !important;
}

#password-reset-title-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

#password-expired-title-txt {
  color: black;
  font-size: 18px !important;
  font-weight: bold !important; 
  clear: both;
  padding-bottom: 8px !important;
}

#password-expired-title-info {
  color: black;
  padding-top: 8px !important;
}

#password-expired-title-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

#okta-sign-in .recovery-challenge .send-email-link {
  text-align: inherit;
  padding-bottom: 0px;
  margin-bottom:0.4rem;
}

#okta-sign-in .recovery-challenge .o-form-fieldset-container {
  display: block
  }

.anchor-link {
  color: #27251F !important;
  text-decoration: underline !important;
}

#okta-sign-in a.anchor-link {
  font-size:16px !important
}

#empty-div {
  height: 300px;
  display: none;
}
.footer-container {
  font-size: 12.6px;
  color:$dark-text-color;
  /*NEW*/
  text-align: center;

  }
.footer-container-relative {
  font-size: 12px;
  color:$dark-text-color;
  text-align: center;
  margin-top:8rem;
  margin-bottom:1rem;
  padding:0;
  @media screen and (max-height: 991px) {
        margin-top:6rem;
      }
  @media(max-width:616px){
        padding:0 4.375rem;
    } 
  @media(max-width:1680px){
    margin-top: 4rem;
} 
  @include media-xxl-max {
    margin-top:5rem;
  }
  @include media-xl-max {
    width: 100%;
    text-align: center;
    margin-top:5rem;
    margin-bottom: 2rem;
  } 
  &.footer-login{
    margin:0px;
    text-align:left;
    @include media-xl-max {
      text-align:center;
    }
    #footer,#custom-footer,.copyright{
      width: 50%;
      @include media-xl-max {
        width: 100%;
      }
    }
    .copyright{
      text-align:right;
      @include media-xl-max {
        text-align:center;
      }
      p{
        margin:0px;
      }
    }
    hr{
      margin:0px;
    }
  }
#footer,#custom-footer{
  height: max-content;
  width: 100%;
}
    }
    .legal-privacy{
      text-decoration: underline !important;
      margin:0px 3px;
      &:first-child{
        margin-left:0px;
      }
      &:last-child{
        margin-right:0px;
      }
      &:hover,&:focus{
        color:$button-bg-color;
      }
    }
.legal-privacy,#footer,#custom-footer p {
  text-decoration: none;
  color: $dark-text-color;
  font-size: 12px;
  font-weight: 400 !important;

 
//   @media (max-width:629px) {
//     text-align: center;  
// }
}

.margin-left-widget {
  //margin-right: 80%;
  //margin-right: 53%;
  // max-width: 712px;
  

}

.passcodeInputbox{
  padding-bottom: 20px !important
}

.margin-center-widget {
 //border: 1px solid #bbb;
  margin-left: 33%;
  margin-right: 33%;
  @media (max-width:820px) {
    text-align: center;
    border:1px;
    border-color: rgb(204, 204, 204);
     margin-left:0%;
     margin-right:0%
}
}

.js-unlock { display: none;  }




/* duplicate for covreage testing*/
.o-form-input-group .o-form-control:last-child {
  top:20%;
}

.okta-form-infobox-warning {
  margin-top:40px;
}

.enroll-call-phone {
  width:160px;
}

.enroll-call-extension {
  width:90px;
}

.o-form-input-name-phoneExtension {
  margin-top:7px;
}

.o-form-input-passcode{
  font-size: $font-size !important;
 
  height: 30px;
  width: 250px;
}
  .o-form input {
    font-size: $font-size !important;
   

    &[id="loginForm.securityAnswer"] {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;
      background-color: #fff;
      border-color: #bbb;
      top: -5px;
      height: 25px;
      border: 1px solid #bbb;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 6px 8px;

    }

    &[id="loginForm.customSecurityQuestion"] {
      width: 100%;
      margin-top: 5px;
      background-color: #fff;
      border-color: #bbb;
      top: -5px;
      height: 25px;
      border: 1px solid #bbb;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 6px 8px;
    }
  }


  //  .o-form select {
  //   font-size: $font-size !important;
  //  
  //   width: 100% ;
  //   inline-size: 100%;
  //   overflow-wrap: break-word;
  //   height:23px;
  // }

  .o-form-explain.okta-form-input-error {
    padding-top: 0px;
    padding-bottom: 10px;
    width: 350px
  }

  .o-form-input-error .icon-16 {
    left: -2px;
    position: absolute !important;
    top: 0px;
  }

  .o-form-theme {
    padding: 0 0 0;
  }


  .o-form-button-bar {
    padding: 0 0 1px;
  }
  

  
  .auth-content .help-links {
   li {
     margin-top: 8px;
     cursor: pointer;
   }
  }


  // .okta-sign-in-header {
  // /* -- Main Background and Border Colors -- */
  //   border-bottom-color: #ddd;
  //   padding-bottom: 15px;
  //   padding-top: 15px;
  // }


  .auth-org-logo {
		max-width: 156px;
		//position: relative;
  	//left: -80px;
	}

  .auth-content {
    padding: 20px 20px 10px 20px;
   
   
  
   
  }


  .auth-content-inner {
    /* -- Main Background and Border Colors -- */
    position: relative;
  	top:0px;
  }

  .okta-sign-in-beacon-border {
  /* -- Main Background and Border Colors -- */
    border-color: #a7a7a7;
  }

  .okta-form-divider {
  /* -- Main Background and Border Colors -- */
    border-bottom-color: #e8e8e8;
  }

  .factor-icon,
  .qrcode-image {
  /* -- Main Background and Border Colors -- */
    border-color: #ccc;
  }

  .auth-beacon {
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
  }

  .enroll-totp .o-form-label legend {
    color: #777;
    margin-top: 25px;
}

  .mfa-okta-verify-30,
  .enroll-factor-row .mfa-okta-verify {
    /* -- Factor Icons (small): Okta Verify -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_ico@2x.png');
    }
  }

  .mfa-okta-verify {
    /* -- Factor Icons (large): Okta Verify -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/oktaVerify@2x.png');
    }
  }


  .mfa-okta-sms {
    /* -- Factor Icons (large): SMS -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/sms_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/sms@2x.png');
    }
  }

  .mfa-call-30,
  .enroll-factor-row .mfa-okta-call {
    /* -- Factor Icons (small): CALL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_ico@2x.png');
    }
  }

  .mfa-okta-call {
    /* -- Factor Icons (large): CALL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/voicecall@2x.png');
    }
  }
  
  .mfa-email-30,
  .enroll-factor-row .mfa-okta-email {
    /* -- Factor Icons (small): EMAIL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_ico@2x.png');
    }
  }

  .mfa-okta-email {
    /* -- Factor Icons (large): EMAIL -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/email@2x.png');
    }
  }

  .mfa-question-30,
  .enroll-factor-row .mfa-okta-security-question {
    /* -- Factor Icons (small): Security Question -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_38x38.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_ico@2x.png');
    }
  }

  .mfa-okta-security-question {
    /* -- Factor Icons (large): Security Question -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/question@2x.png');
    }
  }

  .mfa-okta-password {
    /* -- Factor Icons (large): Password -- */
    background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/password_70x70.png');

    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Password -- */
      background-image: url('https://op1static.oktacdn.com/assets/img/icons/mfa/password@2x.png');
    }
  }

  .button {
  /* -- Buttons' Colors -- */
    @include light-button-template($button-bg-color);
    font-size: $font-size !important;
   
    height: 40px;
    line-height: 16px;
    margin-bottom: 8px !important;
    width: 250px !important;
    left: 1.5%;
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: $button-text-color;

    &:focus {
      border-color: $input-border-color-focus;
    }
  }

  .button-dark {
    /* -- Buttons' Colors -- */
    @include dark-button-template($dark-button-bg-color);
  }

  .button-primary {
  /* -- Buttons' Colors -- */
    @include dark-button-template($primary-button-bg-color);
    
  }

  .button-success {
  /* -- Buttons' Colors -- */
    @include dark-button-template($success-button-bg-color);
  }

  .button.link-button {
    padding: 10px 5px 5px 5px !important; // added for button resend
    /* -- Buttons' Colors -- */
    &:hover,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
      //background-color: antiquewhite;
    }
  }

  .button-custom {

    @include light-button-template($button-bg-color);
    font-size: $font-size !important;
   
    height: 40px;
    line-height: 16px;
    margin-bottom: 8px !important;
    width: 250px !important;
    left: 1%;
    position: relative;
    text-align: center;
    vertical-align: middle;

    &:focus {
      border-color: $input-border-color-focus;
    }
    padding: 10px 5px 5px 5px !important; // added for button resend
   
      cursor: default;
      background-image: none;
      background-color: #e41f35;
      // background: -ms-linear-gradient(top, #fd0000 0%, #ee0000 100%);
      // background-image: linear-gradient(#fd0000, #ee0000);
      border-color: darkred;
      //border-bottom-color: #860000;
      text-decoration: none;
      box-shadow: none
      
  }
    
  .button.link-button-custom-enabled {
    @include light-button-template($button-bg-color);
    font-size: $font-size !important;
   
    height: 40px;
    line-height: 16px;
    margin-bottom: 8px !important;
    width: 250px !important;
    left: 1%;
    position: relative;
    text-align: center;
    vertical-align: middle;

    &:focus {
      border-color: $input-border-color-focus;
    }
    @include dark-button-template($primary-button-bg-color);
    padding: 10px 5px 5px 5px !important; // added for button resend
    cursor: default;
    background-image: none;
    background-color:#e41f35;
    border: rgb(197, 191, 191);
    
  }

 


  .special-label{
    display: none;
  }

  // .flag-dropdown{
  //   display:none
  // }

  // .selected-flag{
  //   display:none
  // }
 
  .button.link-button-disabled {
    padding: 5px 5px 5px 5px !important; // added for button resend
    /* -- Buttons' Colors -- */
    color: #625f5f;
    border: #DDDDDD;
    background: #DDDDDD !important;
    &:hover,
    &:focus,
    &:active,
    &:focus {
    /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  #dialCode {
    box-shadow: 0 0px 0px 0  !important;
    background:  #f4f6f6 !important;
    padding: 6px 10px !important;
 }

 #brDiv{
   display: none;
 }

 ::placeholder {
  color:#ccc;
  font-size: $font-size-medium !important;
  
}

#okta-sign-in .o-form-label,#okta-sign-in .okta-form-label {
  padding: 18px 10px 0px 0 !important;
}

.Title {
  width: 398px;
  height: 33px;
  margin: 0 24px 8px 528px;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  position: absolute;
  left: 520px;
  z-index: -2;
  top: 105px;
}

.divider {
  width: 950px;
  height: 16px;
  margin: 8px 0 0;
  border: solid 1px #e41f35;
  background-color: #e41f35;
  position: absolute;
  left: 520px;
  z-index: -2;
  top: 127px;
}
/*
.footer-divider {
  width: 1364px;
  height: 1px;
  margin: 245.2px 0 44.5px 0.5px;
  background-color: #ccc;
}
*/
.footer-divider {
  width: 1150px;
  height: 1px;
  margin: 39.2px 207.5px 0.5px;
  background-color: #ccc;
}

// footer {
//   width: 134.5px;
//   height: 45px;
//   margin: 0px 0 0;
//   padding: 5px 0.5px 0 0;
// }

.Legal-Privacy-Policy {
  width: 479px;
  height: 32px;
  //margin: 0 406px 0 0;
  margin:52px 406px 50px 208px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $color-gray-text;
}
.Cardinal-Health-Inc-All-rights-reserved {
  width: 479px;
  height: 32px;
  margin: 0 0 0 406px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $color-gray-text;
}
/*
#okta-sign-in.auth-container .auth-content {
  @media only screen and (max-width: 600px){
    max-width: none;
  }
}*/

.login-wrapper {
  margin-top: 0rem;
@include media-xl-max {
   margin: 0;
}
}
// .login-container .container {
// border-radius: 12px;
// border-top-left-radius: 4px;
// border-top-right-radius: 4px;
// box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
// background-color: #fff;
// margin-bottom: 55px;
// position: relative;
// @media (min-width: 1792px){
//   max-width: 1608px;
// }
// @include media-xxxl-max {
//   max-width: 1400px !important;
// } 
// @include media-xxl-max {
//     max-width: 1260px !important;
// } 
// @media(max-width:1340px){
//     max-width: 1160px !important;
// }
// @include media-xl-max {
//     max-width: 1100px !important;
// } 
// @include media-xxxl-max {
//   margin-bottom: 50px;
// } 
// @include media-xxl-max {
//   margin-bottom: 45px;
// } 
// @include media-xl-max {
//  border-radius: 0px;
//  box-shadow: unset;
// } 
// &::before{
//   background: var(--bs-gray-600);
//   height: 5px;
//   width: 100%;
//   content: "";
//   position: absolute;
//   left: 0px;
//   top: -4px;
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;
//   @include media-xl-max {
//     display: none;
// } 
// }
// }

.phx-form-login {
  max-width: 420px;
  margin: 0 auto;
}

.footer-login{
  .container{
      padding: 0;
      @media (min-width: 1792px){
        max-width: 1608px;
      }
      @include media-xxxl-max {
        max-width: 1400px !important;
      } 
      @include media-xxl-max {
          max-width: 1260px !important;
      } 
      @media(max-width:1340px){
          max-width: 1160px !important;
      }
      @include media-xl-max {
          max-width: 1100px !important;
      } 
  }
}

#inner-pages-banner-image{
  background-color: #e7f5f7;
//   @media screen and (max-height: 950px) {
//     max-height: max-content;
// }
//   @include media-xxxl-max {
//     max-height: max-content;
//     overflow:unset;
//   }
}
.login-image-right{
  position: relative;
  background-color: #e7f5f7;
  min-height: 100vh;
    height: 100%;
  // max-height: 100vh;
  overflow:hidden;
  background-repeat: no-repeat;
  background-size: 100% auto;
  // height: 100%;
  .login-text-over-image{
      max-width: 479px;
      position: absolute;
      top: 30%;
      left: 29%;
      background:#ffffff;
     
      @media(max-width:2300px){
        top: 24.8em;
      }
      @media(max-width:2000px){
        top: 22.5em;
      }
      @media(max-width:1900px){
        top: 22em;
      }
      @media(max-width:1820px){
        top: 21em;
      }
      @include media-xxxl-max {
        max-width: 400px;
        top: 20em;
    } 
    @media(max-width:1700px){
      top: 19em;
    }
    @media(max-width:1600px){
      top: 17.5em;
    }
    @media(max-width:1500px){
      top: 17em;
    }
      @include media-xxl-max {
          max-width: 350px;
          top: 16em;
      } 
      @media(max-width:1377px){
        top: 15em;
      }
      @media(max-width:1300px){
        top: 14em;
      }
      @media(max-width:1280px){
        top: 13.5em;
      }
      p{
        
        font-weight:500 !important;
        // font-size: 1.1rem;
        color:$button-bg-color !important;
        font-family: $font-family-body !important;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.06px;
        text-align: left;
      }
      h3{
          font-size: 2.05rem;
          color:$text-primary-color;
          margin: 21px 0 0;
          font-family: $font-family-body !important;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: 0.03px;
          text-align: left;
         
          @include media-xxxl-max {
            font-size: 1.8rem;
        }
        @include media-xxl-max {
          font-size: 1.7rem;
      }
          @include media-xl-max {
              font-size: 1.6rem;
          } 
         
      }
  }
}
#okta-sign-in .o-form .input-fix input[type=number], #okta-sign-in .o-form .input-fix input[type=password], 
#okta-sign-in .o-form .input-fix input[type=tel], 
#okta-sign-in .o-form .input-fix input[type=text], 
#okta-sign-in .o-form .input-fix input[type=textbox],
#okta-sign-in.auth-container .okta-form-input-field input,
#okta-sign-in .o-form .passcode-input-box .o-form-input .o-form-control {
  padding: 15px 15px 16px 16px;
  border-radius: 4px !important;
  border: solid 1px $dark-text-color;
  min-height:52px;
  letter-spacing: 0.03px;
  text-align: left;
  color:$text-primary-color;
  font-size:var(--bs-body-font-size);
  width: 100%;
  font-family:$font-family-body !important;
  line-height: 1 !important;
  &::placeholder{
    color: $dark-text-color !important;
    font-size:var(--bs-body-font-size) !important;
    font-family:$font-family-body !important;
  }
  &:hover{
    box-shadow: inset 0rem 0rem 0rem 0.1rem $text-primary-color;
    border-color: $text-primary-color;
  }
  &:focus:not(:disabled) {
    // outline: 0.125rem dotted $dark-text-color;
    // outline-offset: 0.313rem;
    border-radius: 4px;
  }
  &[aria-invalid="true"]{
    border-color:$error-pink-color !important;
    &:hover{
      box-shadow: inset 0rem 0rem 0rem 0.1rem $error-pink-color;
      border-color: $error-pink-color;
    }
  }
  &.operations-supplierCapacity{
    // border: solid 1px $dark-text-color !important;
    // border-right:0px !important;
    // border-top-right-radius:0px !important;
    // border-bottom-right-radius:0px !important;
    border: 0px !important;
  }
}
input:-internal-autofill-selected {
  &:hover{
    background: #fff;
    background-color: #fff !important;
  }
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}
.phone-input-selct-country-both{
  position: relative;
  margin: 5px 0 8px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  input[disabled]{
    color: $text-primary-color !important;
    -webkit-text-fill-color: $text-primary-color !important; 
    opacity: 1 !important;
  }
  #dialCode.operations-supplierCapacity{
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 1;
    min-height: 38px !important;
    
  }
}
#okta-sign-in .o-form .o-form-input .o-form-control {
  height: max-content;
  border:0px;
  border-radius:0px !important;
}

.login-wrapper-inner-page{
.beacon-heading-login{
  display:none;
}
}
body .inner-pages-banner-image{
  display:none !important;
}

.login-wrapper-inner-page{
  .left-box{
    margin:0 auto;
  }
  .phx-form-login{
    max-width:656px;
    margin:0 auto;
    @include media-xl-max {
    max-width:455px;
  } 
  }
  .margin-center-widget{
    margin:0 auto;
   
  }
  .auth-org-logo{
    margin:0 auto !important;
  }
  .auth-content{
    padding-top:4.7rem !important;
    max-width:384px;
    margin:0 auto !important;
    text-align:left;
    @include media-xl-max {
      padding:3rem 20px 0px !important;
  } 
    hr{
      display:none !important;
    }
  }
  #okta-sign-in.auth-container .okta-sign-in-header{
    padding: 35px 90px 40px !important;
    position: relative;
    border-bottom: 1px solid #ddd;
    @include media-xxl-max {
      padding: 25px 40px 30px !important;
    }
    @media(max-width:576px){
      padding: 2rem 0 !important;
      // border:0px !important;
      margin-bottom:3rem !important;
    }
  }
  .phx-form-login {
    border-radius: 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
    background-color: #fff;
    margin-bottom: 55px;
    padding-bottom:4.5rem;
    position: relative; 
    @include media-xxxl-max {
      margin-bottom: 50px;
    } 
    @include media-xxl-max {
      margin-bottom: 45px;
    } 
    @include media-xl-max {
      padding-bottom:2rem;
    }
    @include media-sm-max {
    border-radius: 0px;
    box-shadow: unset;
    } 
    &::before{
      background: var(--bs-gray-600);
      height: 5px;
      width: 100%;
      content: "";
      position: absolute;
      left: 0px;
      top: -4px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @include media-sm-max {
        display: none;
    } 
    }
  }

}
body #okta-sign-in,#okta-sign-in.auth-container.main-container{
  margin: 0;
  margin-top: 7rem;
  font-family: $font-family-body !important;
  font-size: var(--bs-body-font-size) !important;
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color:$text-primary-color;
  @media screen and (max-width: 1920px), 
       screen and (max-height: 950px) {
        margin-top: 4rem;
}
  @media(max-width:1680px){
    margin-top: 4rem;
} 
  @include media-xxl-max {
    margin-top: 3rem;
} 
@include media-xl-max {
  margin-top: 7rem;
} 
  @include media-sm-max {
    margin: 0;
} 
}
.login-wrapper-inner-page #okta-sign-in.auth-container.main-container{
  margin-top: 5rem !important;
  @include media-xl-max {
    margin-top: 4rem !important;
  } 
  @include media-sm-max {
    margin: 0 !important;
} 
}
.login-wrapper-inner-page .login-container{
  min-height: calc(100vh - 57px) !important;
  margin-bottom:5rem;
  @include media-xl-max {
    min-height: calc(100vh - 150px) !important;
  }
}

#okta-sign-in [class*="-16"]:after, #okta-sign-in [class*="-16"]:before, #okta-sign-in [class^="-16"]:after, #okta-sign-in [class^="-16"]:before{
  //font-family: $font-family-material-icon !important;
  content: "";
    // font-weight: 500 !important;
    // font-style: normal;
    // font-size: 1.3rem;
    // line-height: 1;
    // letter-spacing: normal;
    // text-transform: none;
    // display: inline-block;
    // white-space: nowrap;
    // word-wrap: normal;
    // direction: ltr;
    // -webkit-font-feature-settings: "liga";
    // -webkit-font-smoothing: antialiased;
    // color:$text-primary-color;
}
#okta-sign-in .visibility-16:before{
  content: "\e8f4";
  color:$text-primary-color !important;
}
#okta-sign-in .visibility-off-16:before{
  content: "\e8f5";
  color:$text-primary-color !important;
}
#okta-sign-in .o-form .input-fix .password-toggle .eyeicon{
  right:10px;
  opacity: 1 !important;
  background-repeat: no-repeat;
    background-position: center;
  &.button-show{
    background-image:url(../images/eye.png);
    background-size: 19px;
  }
  &.button-hide{
    background-image:url(../images/eye-off.webp);
    background-size: 19px;
    margin-top: 0px;
  }
  &:before,&:after{
    display:none;
  }
}
#okta-sign-in.auth-container .forgot-username .button,
#okta-sign-in.auth-container .reactivate-account .button,
#okta-sign-in.auth-container  .forgot-password .button.email-button{
  margin-bottom:0px !important;
}
.auth-footer .link.js-back{
  margin-top: 0.7rem !important;
    display: inline-block;
}
#okta-sign-in.auth-container .primary-auth .button{
  margin-bottom:0px !important;
}
#okta-sign-in .infobox{
  @include media-sm-max {
    padding-left:40px !important;
    padding-right: 10px !important;
  } 
}

.skip-times {
  margin-top: 1.5rem !important;
 
  p{
      font-size: 0.788rem !important;
      line-height: 1.27;
      color:$dark-text-color !important;
      letter-spacing: 0.01px;
      font-family: $font-family-body !important;
      font-weight: 400 !important;
      span{
          font-weight: 500 !important;
      }
  }

}

.login-wrapper-inner-page .footer-login-home{
  display:none;
}
.login-wrapper-inner-page .footer-inner-pages-for-home{
  display:block !important;
}
#okta-sign-in.auth-container .account-recovery-setup-head-body div{
  font-family: $font-family-body !important;
  h6{
    font-weight: 500 !important;
    color:$text-primary-color !important;
    padding-bottom:4px !important;
  }
  p{
     color:$dark-text-color !important;
     font-weight:400 !important;
  }
}
#okta-sign-in{
  border-radius:0px !important;
}
.errormessae-width-email{
  max-width:370px;
}
#okta-sign-in .recovery-challenge .o-form-fieldset.enroll-sms-phone{
  width:100% !important;
  margin-bottom:0px;
}
#okta-sign-in .o-form-button-bar .button+.button, #okta-sign-in .o-form-toolbar .button+.button, #okta-sign-in .o-form-toolbar.ui-tabs .button+.button{
  margin-left:0px !important;
}
.recovery-challenge .o-form-button-bar{
  margin-top:0rem !important;
}
.custom-gray-text {
  display:flex;
  flex-wrap:wrap;
  width:100%;
}
#okta-sign-in.auth-container{
  .custom-gray-text {
    &.custom-gray-text{
      p{
        color: $dark-text-color !important;
        font-size: var(--bs-body-font-size) !important;
        span{
          font-weight: 700 !important;
        }
        a{
          color: $dark-text-color !important;
          font-size: var(--bs-body-font-size) !important;
          font-weight: 400 !important;
          text-decoration: underline !important;
          &:hover, &:active,:visited{
            color: #164f61 !important;
          }
        }
      }
    }
  }
}
#okta-sign-in .o-form-fieldset {
  float: left;
  width: 100%;
  margin-bottom:0px;
}
.account-unlock .mobile-recovery-hint{
  margin-bottom:1.5rem !important;
  display:flex;
  width:100%;
}
.forgot-username br,.reactivate-account br{
  display:none;
}
#okta-sign-in.auth-container .react-tel-input.country-select-operation{
  margin-bottom: -95px !important;
  position: relative;
  z-index: 2;
  top: 47px;
  width: 20px;
  left: 50px;
  .form-control[type="tel"] {
    font-size: 0px !important;
    width: 20px;
    background-position-x: 5px;
    padding: 0;
    /* position: absolute; */
    border: 0;
    background-color: transparent;
    background-image: url(../images/expandmore.png);
    background-size: 11px;
    min-height: 20px;
    top: 14px;
    &.open{
      transform: rotate(-180deg);
    }
    
  }
}

#okta-sign-in.auth-container .error-border-phone .okta-form-input-field-customUi-phone .react-tel-input .form-control,
#okta-sign-in .o-form .error-border-phone .input-fix input[type=text].operations-supplierCapacity{
border-color:$error-pink-color !important;
&:hover{
  box-shadow: inset 0px 0px 0px 0.1rem $error-pink-color;
  border-color: $error-pink-color ;
}
}
body .react-tel-input .flag-dropdown{
  top: 7px;
  height: 38px;
  bottom: unset;
  width: 63px;
  left: -39px;
  border-radius: 4px !important;
  &:hover{
    box-shadow: inset 0px 0px 0px 2px #000;
  }
  .selected-flag{
    width: 100%;
    border-radius: 4px !important;
    &:focus{
      outline: 2px dotted #6b7070 !important;
      outline-offset: 1px !important;
      border-radius: 2px !important;
    }
  }
}

// #okta-sign-in .forgot-username .o-form-fieldset-container{
//   margin-top:1.5rem;
// }
// .custom-gray-text{
//   float:left;
// }
// .beacon-heading-login{
//   display: none;
//   &:first-child{
//     display: block;
//   }
// }
.forgot-username .sub-heading-inner-page.recovery-text {
  color:$dark-text-color !important;
}

.login-container .auth-content-inner{
//   max-height: 500px;
//   overflow-y: auto;
//   padding: 0px 20px !important;
//   margin-left: -20px !important;
//   margin-right: -20px !important;
//   @media screen and (max-height: 1000px) {
//         max-height: 450px;
// }
// @media screen and (max-height: 950px) {
//         max-height: max-content;
// }
//   @include media-xxxl-max {
//     max-height: max-content;
//   } 
//     @include media-sm-max {
//       max-height: max-content;
//   } 
  // &::-webkit-scrollbar {
  //   width: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: $text-primary-color;
  // }
  // &::-webkit-scrollbar-track {
  //   background:#e2e9ea;
  // }
}
.login-wrapper .login-container .main-container{
  min-height: 70vh;
  @media(max-width:2000px){
    min-height: 73vh;
  }
  @include media-xxl-max {
    min-height: max-content;
  } 
}
// .login-wrapper .left-box{
//   max-height: 100vh;
//   overflow-y: auto;
// }
.login-wrapper.login-wrapper-inner-page .left-box{
  max-height: max-content;
  overflow-y: unset;
}
.login-wrapper.login-wrapper-inner-page .login-container .main-container{
  min-height: max-content !important;
}
.login-wrapper.login-wrapper-inner-page .login-container .auth-content-inner{
  max-height: max-content;
  overflow-y: unset !important;
  padding: 0px 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
// #okta-sign-in.auth-container .account-unlock .o-form-explain.okta-form-input-error{
//   font-size: 0px !important;
//   &:after{
//     content:'Username is required.'; 
//     visibility: visible;
//     display: block;
//     width: 100% !important;
//     color: #b13590 !important;
//     font-weight: 400 !important;
//     font-size: 14.2px !important;
//     word-break: break-word !important;
//     -webkit-hyphens: unset !important;
//     -ms-hyphens: unset !important;
//     hyphens: unset !important;
//     margin-top: -26px;
//     position: relative;
//     top: -1px;
//     @include media-sm-max {
//       top: -1px;
//       position: relative;
//     } 
//   }
// }
#okta-sign-in.auth-container .recovery-challenge .o-form-explain.okta-form-input-error
#okta-sign-in.auth-container .recovery-challenge .o-form-explain.okta-form-input-error,
#okta-sign-in.auth-container .recovery-challenge .o-form-explain.okta-form-input-error#input-container-error53,
#okta-sign-in.auth-container .recovery-challenge .okta-form-input-error.o-form-input-error.o-form-explain{
  font-size: 0px !important;
  &:after{
    content: "Passcode is required." !important;
    visibility: visible;
    display: block;
    width: 100% !important;
    color: #b13590 !important;
    font-weight: 400 !important;
    font-size: 14.2px !important;
    word-break: break-word !important;
    -webkit-hyphens: unset !important;
    -ms-hyphens: unset !important;
    hyphens: unset !important;
    margin-top: -26px;
    position: relative;
    top: -1px;
    @include media-sm-max {
      top: -1px;
      position: relative;
    } 
  }
}

.margin-top-12{
  margin-top: 12px !important;
}

// #okta-sign-in.auth-container .recovery-challenge  .o-form-has-errors .infobox-error{
//   p{
//     font-size: 0px !important;
//     &:after{
//       content:'Please review required fields.'; 
//       visibility: visible;
//       display: block;
//       width: 100% !important;
//       font-size: var(--bs-body-font-size) !important;
//     }
//   }
// }

.custom-modal {
  font-family: 'Roboto', sans-serif ;
  display: none;
  width: 520px;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  border-radius: 4px;
  color: #27251f;
  @include media-sm-max{
    width: 100%;
  }
  .modal-content {
    @include media-sm-max{
      height:100vh;
      border: 0;
      border-radius: 0;
    }
    .modal-header {
      font-family: 'Roboto-Medium', sans-serif ;
      display: flex;
      justify-content: space-between;
      padding: 2rem 2rem 1rem 2rem;
      .header-title {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .modal-body {
      padding: 0 2rem 2rem 2rem;
      font-size: 16px;
      .inline-notification {
        width: 100%;
        border: 1px solid #1274bf;
        background-color: #f8fbfe;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4px;
        .label-text {
          font-weight: 600;
          color: #1274bf;
        }
      }
    }
    .modal-footer {
      padding: 1rem 2rem;
      border-top: 1px solid #6b7070;
    }
  }

  .icon-button {
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color:#27251f;
    border-radius: 50%;
    border: none;
    padding: 0;
    &:hover {
      background-color: #F4F6F6 !important;
      box-shadow: 0 0 0 0.125rem #27251F !important;
      color: #27251F !important;
    }
    &:active {
      background-color: #e2e9ea !important;
      box-shadow: 0 0 0 0.125rem #27251f !important;
      color: #27251f !important;
    }
  }

  .btn {    
    box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
    font-family: 'Roboto', Helvetica, sans-serif !important;
    height: 52px;
    border-radius: 3px;
    line-height: 16px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    padding: 16.5px 16px;
    &.button-primary {
      color: #fff;
      background: #e41f35;
      background-color: #e41f35;
      border-color: #e41f35;
      &:hover {
        background-color: #B22233 !important;
        border-color: #B22233 !important;
      }
      &:active {
        background-color: #6D151F !important;
        border-color: #6D151F !important;
        color: #fff;
      }
      &:focus {
        border: 1px solid white !important;
      }
    }
    &.button-secondary {
      color: #27251f;
      background: #fff;
      border-color: #27251f;
      margin-right: 1rem;
      &:hover {
        background-color: #F4F6F6 !important;
        border-color: #27251F;
        // box-shadow: inset 0px 0px 0px 1px #27251F;
        color: #27251F;
      }
      &:active {
        background-color: #E2E9EA !important;
        border-color: #27251F;
        // box-shadow: inset 0px 0px 0px 1px #27251F;
        color: #27251F;
      }
    }
  }
}
#okta-sign-in .infobox>.icon{
  top:11px !important;
}

.background-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 37, 31, 0.75);
  z-index: 999;
}
.page-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner-loader-big {
  width: 84px;
  height: 84px;
  border: 8px solid #e2e9ea;
  border-bottom-color: #e41f35;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite
}

@keyframes rotation {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}